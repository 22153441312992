import { faAngleLeft, faAngleRight, faDownload, faEdit, faFile, faQrcode, faTable, faTag, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import throttle from "lodash.throttle"
import { useEffect, useRef, useState } from "react"
import { Badge, Button, CloseButton, Form, InputGroup, Modal, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { Document, Page, pdfjs } from "react-pdf"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { useNavigate, useParams } from "react-router"
import { backendUrl, useConfiguredAxios } from "./ConfiguredAxios"
import DocumentEdition from "./DocumentEdition"
import DocumentLinks from "./DocumentLinks"
import "./DocumentView.css"
import Events from "./Events"
import { DocumentDetails } from "./model"
import { useAuth0 } from "@auth0/auth0-react"

type Mode = "edition" | "details" | "preview"

const DocumentView = () => {
    const maxFileSizeForPreview = 5e6

    const { documentId } = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState<DocumentDetails | null>(null)

    const axios = useConfiguredAxios()

    const [showDeletionModal, setShowDeletionModal] = useState(false);

    const [mode, setMode] = useState<Mode>("details")

    const closeDeletionModal = () => {
        setShowDeletionModal(false)
    }

    const deleteDocument = async () => {
        try {
            if (documentId) {
                await axios.delete(`/rest/documents/${documentId}`)
                closeDeletionModal()
                Events.documentDeleted(documentId)
                navigate("/")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSave = async (details: DocumentDetails) => {
        try {
            if (documentId) {
                await axios.put(`/rest/documents/${documentId}`, details)
                setDetails(details)
                setMode("details")
                Events.documentUpdated([documentId, details])
            } else {
                console.error("Undefined documentId!")
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`/rest/documents/${documentId}`)
                setDetails(response.data)
                setMode("details")
                setLoading(false)
                // TODO eventual consistency
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    }, [documentId, axios])

    return <>
        <Modal show={showDeletionModal} onHide={closeDeletionModal}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>The document will be deleted permanently, this is an irreversable operation.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDeletionModal}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteDocument}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

        {
            (!loading && details && documentId) ?
                <div className="document-container">
                    <div className="mb-2 mode-selector d-flex justify-content-between align-items-center">
                        <CloseButton onClick={() => navigate("/")} className="mw-2" />

                        <ToggleButtonGroup name="document-mode" type="radio" value={mode} onChange={setMode} className="me-2">
                            <ToggleButton id="document-mode-edit" value="edition" variant="secondary"><FontAwesomeIcon icon={faEdit} /> Edit</ToggleButton>
                            <ToggleButton id="document-mode-details" value="details" variant="secondary"><FontAwesomeIcon icon={faTable} /> Details</ToggleButton>
                            <ToggleButton id="document-mode-preview" disabled={details.file.size > maxFileSizeForPreview} value="preview" variant="secondary"><FontAwesomeIcon icon={faFile} /> Preview</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="contents">
                        {mode === "edition" && <div><DocumentEdition details={details} onSave={onSave} onCancel={() => setMode("details")} /></div>}
                        {mode === "details" &&
                            <div className="details">
                                <h3>{details.paperId && <Badge bg="secondary" className="me-1"><FontAwesomeIcon icon={faQrcode} />&nbsp;{details.paperId}</Badge>}</h3>
                                <h1>{details.name}</h1>

                                <div className="my-2">
                                    <DownloadButton action={`${backendUrl}/rest/files/${details.file.id}?filename=${encodeURIComponent(details.name)}.pdf`} />{" "}
                                    <Button variant="danger" onClick={() => setShowDeletionModal(true)}><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</Button>{" "}
                                </div>

                                <div>
                                    {details.tags.map(tag => <Badge bg="secondary" key={tag} className="m-1"><FontAwesomeIcon icon={faTag} />&nbsp;{tag}</Badge>)}
                                </div>

                                <div className="ocr">
                                    <div>
                                        <h3>OCR</h3>
                                        {details.ocrData ? <p>{details.ocrData.fullText}</p> : <p>No OCR data 🤔</p>}
                                    </div>
                                </div>

                                <div className="links">
                                    <h3>Links</h3>
                                    <DocumentLinks documentId={documentId}></DocumentLinks>
                                </div>
                            </div>
                        }
                        {mode === "preview" && <PdfPreview fileId={details.file.id} />}
                    </div>
                </div> :
                <div className="text-center"><Spinner animation="border" /></div>
        }

    </>
}

interface DownloadButtonProps {
    action: string
}

const DownloadButton = ({ action }: DownloadButtonProps) => {

    const auth0 = useAuth0()

    const formRef = useRef<HTMLFormElement>(null)

    const [token, setToken] = useState<string | null>(null)

    const download = async () => {
        const accessToken = await auth0.getAccessTokenSilently()

        if (accessToken) {
            setToken(accessToken)
        } else {
            console.log("Could not find token")
        }
    }

    useEffect(() => {
        if (token && formRef.current) {
            formRef.current.submit()
            setToken(null)
        }
    }, [token])


    return <>
        {
            token &&
            <form action={action} method="POST" target="_blank" style={{ display: "none" }} ref={formRef}>
                <input type="hidden" name="token" value={token} />
            </form>
        }

        <Button variant="primary" onClick={download}><FontAwesomeIcon icon={faDownload} />&nbsp;Download</Button>
    </>
}

interface PdfPreviewProps {
    fileId: string
}

const PdfPreview: React.FC<PdfPreviewProps> = ({ fileId }) => {
    const [fileUrl, setFileUrl] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)

    const [width, setWidth] = useState<number>(100)

    const wrapper = useRef<HTMLDivElement>(null)

    const auth0 = useAuth0()

    const options = {
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    }

    useEffect(() => {
        const updateSize = () => {
            if (wrapper.current) {
                const rect = wrapper.current.getBoundingClientRect()
                setWidth(Math.floor(rect.width))
            }
        }


        window.addEventListener("resize", throttle(updateSize, 500))

        updateSize()

        return () => {
            window.removeEventListener("resize", throttle(updateSize, 500))
            console.log("Destruction!")
        }
    }, [fileId, wrapper, loading])

    useEffect(() => {
        const prepare = async () => {
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
            try {
                const accessToken = await auth0.getAccessTokenSilently()
                setFileUrl(`${backendUrl}/rest/files/${fileId}?token=${accessToken}`)
                setLoading(false)
            } catch (error) {
                console.error(error)
            }
        }

        prepare()
    }, [fileUrl, auth0, fileId])
    return <>
        {!loading &&
            <div className="pdf-preview text-center" ref={wrapper}>
                <Document className="document" file={fileUrl} options={options} onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)} >
                    <Page pageNumber={currentPage} width={width} />
                </Document>
                <div>
                    <InputGroup className="mt-2">
                        <Button onClick={() => setCurrentPage(currentPage => currentPage - 1)} disabled={currentPage <= 1}><FontAwesomeIcon icon={faAngleLeft} /></Button>
                        <Form.Control type="text" value={currentPage} className="text-center" disabled />
                        <Button onClick={() => setCurrentPage(currentPage => currentPage + 1)} disabled={currentPage >= numberOfPages}><FontAwesomeIcon icon={faAngleRight} /></Button>
                    </InputGroup>
                </div>


            </div>
        }
    </>
}

export default DocumentView