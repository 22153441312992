import { useState } from "react"
import { DocumentDetails } from "./model"
import { Button, FloatingLabel, Form, ListGroup } from "react-bootstrap"
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead"
import { useConfiguredAxios } from "./ConfiguredAxios"
import { Option } from "react-bootstrap-typeahead/types/types"
import "./DocumentEdition.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCancel, faCheck, faSdCard, faSpinner, faTags } from "@fortawesome/free-solid-svg-icons"

interface Props {
    details: DocumentDetails
    onSave: (documentDetails: DocumentDetails) => Promise<void>
    onCancel: () => void
}

const DocumentEdition: React.FC<Props> = ({ details, onSave, onCancel }) => {
    const [data, setData] = useState(details)

    const [saving, setSaving] = useState(false)

    const save = async (tagged: boolean) => {
        try {
            console.log(tagged)
            setSaving(true)
            await onSave({ ...data, tagged })
        } catch (error) {
            console.error(error)
        } finally {
            setSaving(false)
        }
    }

    const setName = (name: string) => {
        setData(data => { return { ...data, name: name } })
    }

    return <div className="p-2">
        <div className="d-flex">
            <FloatingLabel
                controlId="nameInput"
                label="Document name"
                className="mb-3 flex-grow-1"
            >
                <Form.Control type="text" disabled={saving} placeholder="Document name" value={data.name} onChange={(e) => setName(e.target.value)} />
            </FloatingLabel>
            <FloatingLabel
                controlId="paperIdInput"
                label="Paper ID"
                className="mb-3 ms-1"
                style={{ width: "6rem" }}
            >
                <Form.Control type="text" disabled={saving} placeholder="123" value={data.paperId?.toString() || ""} onChange={(e) => {
                    const paperId = (+e.target.value > 0) ? +e.target.value : null
                    return setData(data => { return { ...data, paperId } })
                }} />
            </FloatingLabel>
        </div>

        <div className="my-3">
            <TagTypeahead selected={data.tags} disabled={saving} onChange={selected => setData(data => { return { ...data, tags: selected } })} />
        </div>

        <div>
            <h5>AI zone</h5>
            {data.aiData ? <ListGroup>
                {data.aiData.names.map(name => {
                    return <ListGroup.Item key={name} active={data.name === name} action onClick={() => setName(name)}>{name}</ListGroup.Item>
                })}
            </ListGroup> : <p className="text-center">No AI generated names 🤔</p>}
        </div>



        <div className="text-center my-3">
            <Button variant="secondary" onClick={() => onCancel()} disabled={saving}><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
            {data.tagged ?
                <>
                    <Button variant="secondary" className="ms-1" onClick={() => save(false)} disabled={saving}>{saving ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faTags} />} Save and mark as untagged</Button>
                    <Button variant="success" className="ms-1" onClick={() => save(true)} disabled={saving}>{saving ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCheck} />} Save</Button>
                </>
                :
                <>
                    <Button variant="primary" className="ms-1" onClick={() => save(false)} disabled={saving}>{saving ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSdCard} />} Save</Button>
                    <Button variant="success" className="ms-1" onClick={() => save(true)} disabled={saving}>{saving ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faCheck} />} Save and mark as tagged</Button>
                </>
            }

        </div>
    </div>
}

export default DocumentEdition

interface TagTypeaheadProps {
    disabled: boolean | undefined
    selected: string[]
    onChange: (selected: string[]) => void
}

const TagTypeahead: React.FC<TagTypeaheadProps> = ({ selected, onChange, disabled }) => {
    const axios = useConfiguredAxios()

    const [loading, setLoading] = useState(false)

    const [options, setOptions] = useState([])

    const handleSearch = async (query: string) => {
        setLoading(true)
        console.log(query)

        try {
            const response = await axios.get(`/rest/documents/tags`, { params: { q: query } })
            console.log(response.data)
            setOptions(response.data)
            setLoading(false)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeInternal = (selected: Option[]) => {
        const strings = selected.map((value) => {
            if (typeof value === "string") {
                return value as string
            } else {
                value as Record<string, any>
                console.log(value)
                return value.label
            }
        })
        onChange(strings)
    }

    const filterBy = () => true;

    return <AsyncTypeahead
        filterBy={filterBy}
        multiple
        disabled={disabled}
        id="document-tags-typeahead"
        isLoading={loading}
        minLength={1}
        onSearch={handleSearch}
        options={options}
        placeholder="Search for tags..."
        allowNew
        selected={selected}
        onChange={onChangeInternal}
        renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>{results.map((result, index) => (
                <MenuItem option={result} position={index}>
                    {typeof result === "string" ? result : result.label}
                </MenuItem>
            ))}</Menu>
        )}
    />
}