import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Outlet, useParams } from "react-router"
import { useConfiguredAxios } from "./ConfiguredAxios"
import DocumentList from "./DocumentList"
import "./MainView.css"
import classNames from "classnames"

export const MainView = () => {
    const [uploading, setUploading] = useState(false)

    const axios = useConfiguredAxios()

    const { documentId } = useParams()

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        acceptedFiles.map(file => file.name)
        console.log(acceptedFiles)
        try {
            setUploading(true)
            await Promise.all(
                acceptedFiles.map(file => {
                    console.log(file.name)
                    return axios.post("/rest/documents", file, { params: { name: file.name } })
                })
            )
            setUploading(false)
        } catch (error) {
            console.error(error)
            setUploading(false)
        }
    }, [axios])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })

    return <div className="main-container">
        <div className={classNames({ "flex-grow-1": true, "left-pane": true, "d-md-block": true, "d-none": !!documentId })} {...getRootProps()}>
            <input {...getInputProps()} />

            {uploading ? <div className="text-center my-3"><h3><FontAwesomeIcon icon={faCloudArrowUp} beatFade /><br />Uploading...</h3></div> :
                (
                    isDragActive ?
                        <div className="text-center my-3"><h3><FontAwesomeIcon icon={faCloudArrowUp} /><br />Drop the files to upload</h3></div> : <DocumentList />
                )
            }
        </div>
        {documentId &&
            <div className="right-pane flex-grow-1">
                <Outlet />
            </div>
        }



    </div>
}

export default MainView