import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'
import { useMemo } from 'react'


export const backendUrl = process.env.REACT_APP_BACKEND_URL


const configuredAxios = axios.create({ baseURL: backendUrl })

export default configuredAxios;


export const useConfiguredAxios = () => {
    const auth0 = useAuth0()

    return useMemo(() => {
        const configuredAxios = axios.create({ baseURL: backendUrl })

        configuredAxios.interceptors.request.use(async config => {
            try {
                const accessToken = await auth0.getAccessTokenSilently()
                config.headers.Authorization = `Bearer ${accessToken}`
                return config
            } catch (e) {
                if (e.error === "invalid_grant") {
                    console.log("Invalid grant, redirecting to sign in...")
                    auth0.loginWithRedirect()
                } else {
                    console.error("Error getting access token", e, typeof e)
                    throw e
                }
            }
        })
        return configuredAxios
    }, [auth0])
}