import { createEventBus, slot } from "ts-event-bus"
import { DocumentId } from "./model"
import { DocumentDetails } from "./model"

const Events = {
    documentDeleted: slot<DocumentId>(),
    documentUpdated: slot<[DocumentId, DocumentDetails]>()
}

export default createEventBus({
    events: Events
})