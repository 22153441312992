import { faLock, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Navbar, Spinner } from 'react-bootstrap';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import DocumentView from './DocumentView';
import MainView from './MainView';

import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const auth0 = useAuth0()

  const signedIn = auth0.isAuthenticated
  const loading = auth0.isLoading

  const signOut = () => {
    auth0.logout({ logoutParams: { returnTo: window.location.origin + "/signed-out" } })
  }

  const router = createBrowserRouter([
    {
      path: "/signed-in",
      element: <Navigate replace to="/" />
    },
    {
      path: "/",
      element: <MainView />,
      children: [
        {
          path: "documents/:documentId",
          element: <DocumentView />
        }
      ]
    }
  ])

  const signIn = () => {
    auth0.loginWithRedirect()
  }

  return (
    <div className="App">
      {(signedIn && !loading) ? <>
        <Navbar bg="dark" variant="dark" expand={false} className="mb-1 navbar">
          <Container fluid>
            <Navbar.Brand href="#">DocSafe</Navbar.Brand>
            <Button variant="dark" onClick={signOut}><FontAwesomeIcon icon={faSignOut} /></Button>
          </Container>
        </Navbar>
        <div className="content">
          <RouterProvider router={router} />

        </div>
      </> : <div className="container text-center my-2">
        {loading ? <div className="text-center my-2"><Spinner animation="border" /></div> : <Button onClick={signIn}><FontAwesomeIcon icon={faLock} /> Sign in</Button>}

      </div>}
    </div>
  );
}

export default App;
