import { faArrowsRotate, faQrcode, faTag, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Buffer } from 'buffer';
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Form, Spinner, ToggleButton } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useNavigate, useParams } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { useConfiguredAxios } from "./ConfiguredAxios";
import "./DocumentList.css";
import Events from "./Events";

interface DocumentItem {
    id: string
    name: string
    aiGeneratedName: string | undefined
    image: string
    hasOcr: boolean
    hasPaperId: boolean
    tagged: boolean
    selectedDate: string | null
}

const DocumentList: React.FC = () => {
    const [loading, setLoading] = useState(true)

    const { documentId } = useParams()

    const [page, setPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const itemsPerPage = 12

    const [documents, setDocuments] = useState<DocumentItem[]>([])

    const [searchTerm, setSearchTerm] = useState("")

    const [untaggedOnly, setUntaggedOnly] = useState(false)

    const [refreshCounter, setRefreshCounter] = useState(0)

    const debounceAndSetSearchTerm = useDebouncedCallback(value => {
        setPage(1)
        setSearchTerm(value)
    }, 300)

    const axios = useConfiguredAxios()

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/rest/documents`, { params: { page, itemsPerPage, query: searchTerm, tagged: untaggedOnly ? false : undefined }, })
                const documents = await Promise.all(response.data.list.map(async (documentWithoutImage: any) => {
                    let image = null
                    try {
                        const response = await axios.get(`/rest/documents/${documentWithoutImage.id}/thumbnail`, { responseType: 'arraybuffer' })
                        image = Buffer.from(response.data, 'binary').toString('base64')
                    } catch (e) {
                        console.error(e)
                    }
                    return { ...documentWithoutImage, image } as DocumentItem
                }))
                setDocuments(documents.map((document: any) => { return { ...document, image: document.image } }))
                setTotalItems(response.data.totalItems)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }

        }

        const onDocumentUpdated = Events.documentUpdated.on(([documentId, details]) => {
            setDocuments(documents => {
                return documents.map(document => {
                    if (document.id === documentId) {
                        return { ...document, name: details.name, hasPaperId: !!details.paperId, tagged: details.tagged }
                    } else {
                        return document
                    }
                })
            })
        })
        const onDocumentDeleted = Events.documentDeleted.on(documentId => {
            setDocuments(documents => {
                return documents.filter(document => document.id !== documentId)
            })
        })

        fetch()

        return () => {
            onDocumentUpdated()
            onDocumentDeleted()
        }
    }, [page, searchTerm, untaggedOnly, refreshCounter, axios])

    return (
        <>
            <div className="document-list-container">
                <>
                    <div className="search px-2 mb-2 w-100">
                        <Form.Control type="search" placeholder="Search..." onChange={e => debounceAndSetSearchTerm(e.target.value)} />
                        <div className="search-buttons">
                            <ToggleButton className="mt-2" variant={untaggedOnly ? "outline-danger" : "outline-secondary"} id="document-list-tagged-only-checkbox" checked={untaggedOnly} value={+untaggedOnly} type="checkbox" size="sm" onChange={(e) => setUntaggedOnly(e.currentTarget.checked)}><FontAwesomeIcon icon={faTag} color="red" />&nbsp;Untagged only</ToggleButton>{" "}
                            <Button className="mt-2" variant="secondary" size="sm" disabled={loading} onClick={() => setRefreshCounter(x => x + 1)}><FontAwesomeIcon icon={faArrowsRotate} />&nbsp;Refresh</Button>
                        </div>

                    </div>
                    <div className="list">
                        {loading ?
                            <div className="text-center my-3"><Spinner animation="border" /></div> :
                            <div className="document-list-grid">
                                {documents.length ?
                                    documents.map(document => <DocumentListItem key={document.id} document={document} selected={document.id === documentId} />) :
                                    <p className="text-center">No results</p>
                                }
                            </div>
                        }

                    </div>
                    <div className="pagination mt-2 justify-content-center">
                        <div className="d-none d-md-block">
                            <PaginationControl total={totalItems} limit={itemsPerPage} page={page} changePage={setPage} between={5} ellipsis={7}></PaginationControl>
                        </div>
                        <div className="d-block d-md-none">
                            <PaginationControl total={totalItems} limit={itemsPerPage} page={page} changePage={setPage} between={2} ellipsis={7}></PaginationControl>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

interface DocumentListItemProps {
    document: DocumentItem
    selected: boolean
}

const DocumentListItem: React.FC<DocumentListItemProps> = ({ document, selected }) => {
    const navigate = useNavigate()

    return (
        <Card className={classNames("m-1 d-flex justify-content-end document-list-item", { "active": selected })} style={{ backgroundImage: document.image ? `url(data:image/png;base64,${document.image})` : "none" }} onClick={() => navigate(`/documents/${document.id}`)}>
            <span className="icons">
                {document.selectedDate ? <><Badge bg="secondary">{document.selectedDate}</Badge>{" "}</> : <></>}
                {!document.tagged && document.aiGeneratedName && <FontAwesomeIcon icon={faWandMagicSparkles} />}{' '}
                {document.hasPaperId && <FontAwesomeIcon icon={faQrcode} />}{' '}
                {!document.tagged && <FontAwesomeIcon icon={faTag} style={{ color: "#c30" }} />}
            </span>
            <Card.Title>{(!document.tagged && document.aiGeneratedName) ? document.aiGeneratedName : document.name}</Card.Title>
        </Card>
    )
}

export default DocumentList