import { faLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useConfiguredAxios } from "./ConfiguredAxios"
import { DocumentId, DocumentLinkDetails } from "./model"

interface DocumentLinksProps {
    documentId: DocumentId
}

const DocumentLinks: React.FC<DocumentLinksProps> = ({ documentId }) => {
    const [loading, setLoading] = useState(true)
    const [links, setLinks] = useState<DocumentLinkDetails[]>([])
    const axios = useConfiguredAxios()

    const otherDocumentId = (link: DocumentLinkDetails) => {
        return (documentId === link.source) ? link.destination : link.source
    }

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await axios.get(`/rest/documents/${documentId}/links`)
                setLinks(response.data)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
        fetchLinks()
    }, [documentId, axios])

    return <>
        {loading ? <div className="text-center"><Spinner animation="border" /></div> :
            <>
                {links.length > 0 ?
                    links.map(link => {
                        return <div key={`${link.source}-${link.destination}`}><Link to={`/documents/${otherDocumentId(link)}`}><FontAwesomeIcon icon={faLink} /> {link.documentName}</Link></div>
                    }) :
                    <p>No links yet</p>
                }
            </>
        }
    </>
}

export default DocumentLinks